/* LandingPage.css */


/* General styles */

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.landing-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: right;
    direction: rtl;
}

header h1 {
    font-size: 2em;
    color: #333;
}

main p {
    font-size: 1.1em;
    line-height: 1.6;
    margin-bottom: 15px;
}


/* Media query for responsiveness */

@media screen and (max-width: 768px) {
    .landing-container {
        padding: 10px;
    }
    header h1 {
        font-size: 1.8em;
    }
    main p {
        font-size: 1em;
    }
}