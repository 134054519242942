.comments {
    display: table-cell;
    width: 50%;
    padding-right: 50px;
    padding-left: 20px;
    background-color: white;
    color: black;
}

.comment {
    padding: "40px 20px";
    display: contents;
}

.comment-container {
    display: contents;
}

.comment-time {
    color: gray;
    font-size: small;
}

.comment-text {
    font-size: medium;
}

.comment-creator {
    margin: 0;
    padding: 10px;
    padding-right: 50px;
    font-size: small;
}