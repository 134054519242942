.display-topics {
  display: inline-table;
  /* border-color: darkblue; */
  /* border-style: groove; */
  margin-right: 10px;
  max-height: 100%;
  height: 100%;
  width: 100px;
  text-align: center;
  align-items: center;
}

.student-actions {
  padding-top: 50px;
  color: black;
}

.student-tabs-view {
  width: 100%;
}
