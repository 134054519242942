.topics {
  width: 70%;
  align-items: center;
  align-content: center;
  margin-top: 50px;
}

.topics_add {
  padding-right: 20px;
  vertical-align: baseline;
}

.topics_data {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.topics_nav {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.topics-grade-select {
  color: black;
  height: 100px;
  padding-top: 20px;
  padding-right: 20px;
}
