.exercise-view-container {
  /* row-gap: 20px; */
  padding-top: 50px;
  width: 80%;
  justify-content: center;
}

.exercise-view-input {
  padding-top: 20px;
  grid-column-start: 1;
  grid-column-end: 2;
  margin-top: 20px;
}

.exercise-view-preview {
  padding-top: 20px;
  direction: ltr;
  margin-top: 20px;
}

.exercise-metadata {
  display: inline-block;
  padding: 5px;
}

.metadata-item {
  margin: 20px 0;
}

.metadata-tags-item {
  display: flex;
}
