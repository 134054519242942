.display-topics {
    display: inline-table;
    /* border-color: darkblue; */
    /* border-style: groove; */
    margin-right: 10px;
    max-height: 100%;
    height: 100%;
    width: 100px;
    text-align: center;
    align-items: center;
    background-color: white;
    color: black;
}

.teacher-view-container {
    display: table-row;
    width: 100%;
    background-color: white;
    color: black;
}

.teacher-view-comments {
    display: table-cell;
    width: 50%;
    padding-right: 50px;
    padding-left: 20px;
    background-color: white;
    color: black;
}

.teacher-view-comment {
    padding: "40px 20px";
    display: contents;
}

.teacher-view-comment-container {
    display: contents;
}

.teacher-view-progress {
    display: table-cell;
    width: 50%;
    padding-right: 100px;
}

.teacher-view-comment-time {
    color: gray;
    font-size: small;
}

.teacher-view-comment-text {
    font-size: medium;
}

.teacher-view-comment-creator {
    margin: 0;
    padding: 10px;
    padding-right: 50px;
    font-size: small;
}