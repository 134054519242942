.subject {
  color: black;
}

.subject-actions {
  margin-top: 150px;
}

.subject_nav {
  width: 100%;
  background-color: white;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
