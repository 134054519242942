.thumbnail-container {
    margin: 10px;
}

.thumbnail {
    width: 100px;
    /* Adjust the width as needed */
    height: 100px;
    /* Adjust the height as needed */
}

.button-container {
    margin-top: 10px;
    /* Adjust the margin as needed */
    display: flex;
    gap: 10px;
    /* Adjust the gap as needed */
}

.image-item {
    flex: 1;
    max-width: calc(50% - 5px);
    min-width: fit-content;
    /* Max of 2 cards per row with a gap of 10px */
    /* Add other styling for the cards as needed */
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Adjust the gap as needed */
}

.thumbnail-container {
    width: 1200px;
    height: 800px;
    margin: 10px;
    overflow: hidden;
    /* Ensure the thumbnail doesn't overflow its container */
}