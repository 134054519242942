.topic {
  width: 70%;
  align-items: center;
  align-content: center;
}

.topic_add {
  padding-right: 20px;
  /* background-color: white; */
  vertical-align: baseline;
}

.topic_data {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.topic_nav {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.topic-grade-select {
  color: black;
  height: 100px;
  padding-top: 20px;
  padding-right: 20px;
}
