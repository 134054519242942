.progress-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Gotham 5r", sans-serif;
    margin-top: 3px;
    margin-bottom: 3px;
    box-sizing: border-box;
    letter-spacing: -0.11px;
    position: relative;
    white-space: nowrap;
    width: 34px;
    height: 34px;
    max-width: 34px;
    max-height: 34px;
    border-radius: 34px;
    font-size: 16px;
    line-height: 16px;
    margin-left: 2px;
    margin-right: 2px;
    border-width: 2px;
    border-color: #c6cacd;
    border-style: solid;
    color: rgb(49, 14, 190);
    margin-right: 30px;
    --fill-percentage: var(--progress-fill, 0%);
    --fill-color: rgb(14, 190, 14);
    --empty-color: hsl(149, 79%, 83%);
    background-image: conic-gradient( var(--fill-color) 0%, var(--fill-color) var(--fill-percentage), var(--empty-color) var(--fill-percentage), var(--empty-color) 100%);
}

.progress-not-selected {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Gotham 5r", sans-serif;
    margin-top: 3px;
    margin-bottom: 3px;
    box-sizing: border-box;
    letter-spacing: -0.11px;
    position: relative;
    white-space: nowrap;
    width: 34px;
    height: 34px;
    max-width: 34px;
    max-height: 34px;
    border-radius: 34px;
    font-size: 16px;
    line-height: 16px;
    margin-left: 2px;
    margin-right: 2px;
    border-width: 2px;
    border-color: #c6cacd;
    border-style: solid;
    border-color: #5b6770;
    color: #5b6770;
    background-color: #fefefe;
    margin-right: 30px;
}

.topic-progress {
    /* transform: rotate(-45deg); */
}

.topic-select-button {
    align-items: center;
    text-align: initial;
}