.action {
    padding-bottom: 10px;
}

.container {
    display: flex;
}

.container:first-child {
    margin-right: 20px;
}

.comments {
    width: 20%;
}

.edit-area {
    flex: 1;
    width: 100%;
}

.progress-area {
    flex: auto;
}