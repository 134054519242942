.family {
  width: 50%;
  text-align: center;
}

.family-container {
  display: flex;
  flex-wrap: wrap;
}

.child-container {
  /* display: inline-block; */
  flex-basis: calc(25% - 10px);
  padding-top: 20px;
  padding-bottom: 1rem;
  text-align: -webkit-center;
  /* margin: auto; */
}
