.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  font-size: calc(10px + 2vmin);
  color: black;
  flex: 0 0 auto;
  margin-bottom: 20px;
  /* Add margin-bottom for spacing */
}

.App-content {
  flex: 1 0 auto;
  display: flex;
  margin-top: 50px;
  justify-content: center;
  /* Center content vertically */
}

.App-footer {
  width: 100%;
  bottom: 0;
}
